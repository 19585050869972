import React from 'react';
import { Link } from 'react-router-dom';
import './home.css';

const Home = () => {
  return (
    <div className="home-container">
      <header className="hero-section">
        <div className="hero-content">
          <h1>Welcome to The Lost Art</h1>
          <p>Rediscover the joy of handwritten letters.</p>
          <Link to="/signup" className="btn btn-primary">Get Started</Link>
        </div>
      </header>

      <section className="steps-section">
        <div className="step step-image-1">
          <h2>Step 1</h2>
          <p>Sign up to get started with your first letter.</p>
        </div>
        <div className="step step-image-2">
          <h2>Step 2</h2>
          <p>Receive a kit with everything you need to write a letter.</p>
        </div>
        <div className="step step-image-3">
          <h2>Step 3</h2>
          <p>Send your letter and wait for a reply from a new friend.</p>
        </div>
      </section>
    </div>
  );
};

export default Home;


