// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
// import "@hotwired/turbo-rails"
import "controllers"
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import 'bootstrap'
// import '../stylesheets/application'
import './application.scss';
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Provider } from 'react-redux'; // If using Redux
// import App from '../components/App'; // If you have a main App component
// import rootReducer from '../redux/reducers';
// Support component names relative to this directory:
const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

Rails.start();
Turbolinks.start();
ActiveStorage.start();