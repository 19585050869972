// src/redux/thunks/userThunks.js

import { saveUserRequest, saveUserSuccess, saveUserFailure } from '../actions/userActions';

export const saveUser = (userData) => {
  return async (dispatch) => {
    dispatch(saveUserRequest());
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    
    try {
      const response = await fetch('/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify({ user: userData }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit. Please try again.');
      }

      const data = await response.json();
      dispatch(saveUserSuccess(data));
    } catch (error) {
      dispatch(saveUserFailure(error.message));
    }
  };
};
