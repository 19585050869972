// src/redux/actions/userActions.js

export const SAVE_USER_REQUEST = 'SAVE_USER_REQUEST';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';

export const saveUserRequest = () => ({
  type: SAVE_USER_REQUEST,
});

export const saveUserSuccess = (user) => ({
  type: SAVE_USER_SUCCESS,
  payload: user,
});

export const saveUserFailure = (error) => ({
  type: SAVE_USER_FAILURE,
  payload: error,
});
