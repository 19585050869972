import React from 'react';

const PackageContents = ({ userId, setStep }) => {
  return (
    <div className="package-contents">
      <h2>Your Package</h2>
      <ul>
        <li>Handwritten letter kit</li>
        <li>Custom pen</li>
        <li>Prepaid envelope</li>
        <li>Instruction manual</li>
      </ul>
      <button onClick={() => setStep(3)} className="btn btn-primary">Proceed to Payment and Shipping</button>
    </div>
  );
};

export default PackageContents;
